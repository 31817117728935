import React, { useState, useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import "./scss/style.scss";

import { useSelector, useDispatch } from "react-redux";
import { selectUser, login, logout } from "./redux_setup/slices/userSlice";
// import { getConsultationCount } from "./constants/Essentials";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Forgot = React.lazy(() => import("./views/pages/forgot"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

// class App extends Component {
const App = (props) => {
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState(true);
  // token is stored somewhere:: perhaps local storage

  // useEffect will check validity of the token:

  useEffect(() => {
    const tempToken = localStorage.getItem("temp_token");

    // console.log(tempToken, user_name, user_role); --for later usee
    //send a request to verify token
    // abortcontroller
    const abortcontroller = new AbortController();
    const signal = abortcontroller.signal;
    const url = `${process.env.REACT_APP_MAIN_URL}/api/users/verify`;

    const dataToSend = {
      emptydata: "",
    };
    if (tempToken && tempToken !== "null") {
      const token = tempToken;
      fetch(url, {
        signal: signal,
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(dataToSend),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.success === 1) {
            console.log("token is valid");

            //after getting token value, store it to local storage
            // localStorage.setItem("temp_token", res.token);
            // localStorage.setItem("temp_user_name", res.name);
            // localStorage.setItem("temp_user_role", res.role);

            //fetch username and role
            const user_name = localStorage.getItem("temp_user_name");
            const user_role = localStorage.getItem("temp_user_role");
            const u_login = localStorage.getItem("temp_login");
            const u_photo = localStorage.getItem("temp_photo");
            const u_special_permissions = localStorage.getItem(
              "temp_special_permissions"
            );

            //dispatch to redux state
            dispatch(
              login({
                userName: user_name,
                role: user_role,
                token: token,
                staffId: u_login,
                photo: u_photo,
                special_permissions: u_special_permissions,
              })
            );

            // //use user role and get the dashboard count components
            // if (user_role === "Doctor") {
            //   getConsultationCount(token, u_login);
            //   // console.log("count is below");
            //   // console.log(consCount);
            // }

            setLoadingState(false);
          } else {
            console.log("Token is not valid");
            //disptach a logout
            dispatch(logout());
            setLoadingState(false);
            //set everything in local to null
            localStorage.setItem("temp_token", null);
            localStorage.setItem("temp_user_name", null);
            localStorage.setItem("temp_user_role", null);
            localStorage.setItem("temp_login", null);
            localStorage.setItem("temp_photo", null);
            localStorage.setItem("temp_special_permissions", null);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingState(false);
        });
    } else {
      dispatch(logout());
      setLoadingState(false);
    }
  }, []);
  // if the token has expired, push userto login screen, else set state to user credentials and proceed
  // access user state from store
  const user = useSelector(selectUser);
  if (loadingState) {
    return (
      <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
        <p>Processing...</p>
      </div>
    );
  } else {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          {!user ? (
            <>
              {/* <Login /> */}
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/forgot-password"
                  name="Reset Password"
                  render={(props) => <Forgot {...props} />}
                />
                <Route>
                  <Login />
                </Route>
              </Switch>
            </>
          ) : (
            <Switch>
              {/* <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          /> */}
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          )}
        </React.Suspense>
      </HashRouter>
    );
  }
};

export default App;
